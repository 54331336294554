import { useEffect, useState } from 'react'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import BaseService from '../helpers/baseServices'
import { RouteConstants } from '../helpers/RouteConstants'

// type AvailableCouponsCardProps = {
//   couponStats: {
//     total_coupons_available: number
//     total_coupons_available_amount: number
//   }
// }

const AvailableCouponsCard = () => {
  const [couponStats, setCouponStats] = useState<any>({
    total_coupons_available: 0,
    total_coupons_available_amount: 0
  })
  const navigate = useNavigate()

  //fetch stats
  const fetchStats = async () => {
    try {
      const response = await BaseService.get_api(`${RouteConstants.ALL_STATS}`)
      const userStats = response.data?.payload
      // console.log(userStats)
      setCouponStats({
        total_coupons_available: userStats.total_coupons_available,
        total_coupons_available_amount: userStats.total_coupons_available_amount
      })
    } catch (error) {
      // console.log(error)
    }
  }

  useEffect(() => {
    fetchStats()
  }, [])

  // console.log(coupons)
  // let couponAmt = 0
  // coupons.forEach((coupon: any) => (couponAmt += coupon.amount_left))

  return (
    <div className='my-5 w-full card-small-container dark:bg-dark-100 dark:shadow-[#424141] dark:shadow-sm'>
      <div className='text-[14px]'>Available Coupons</div>
      <div className='flex items-center justify-between w-full'>
        <div className='flex items-center gap-1'>
          <div className='colored-card-container bg-[#DCFFF1]'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                d='M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z'
                stroke='#2ABB7F'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
              <path
                d='M6 11.9534L9.95342 15.9068L17.84 8'
                stroke='#2ABB7F'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </div>
          <div className='text-left'>
            <div className='font-semibold'>
              GHS
              {Intl.NumberFormat('en-EN').format(
                couponStats.total_coupons_available_amount
              )}
            </div>
            <div className='text-neutral-200 text-xs'>
              {couponStats.total_coupons_available}
            </div>
          </div>
        </div>
        <button
          onClick={() => navigate('/all-coupons?q=available')}
          className='btn-subtle flex gap-2 items-center'
        >
          <div>See all</div>
          <ChevronRightIcon className='w-5 h-5' />
        </button>
      </div>
    </div>
  )
}

export default AvailableCouponsCard
