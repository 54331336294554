import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { StorageBox } from '../../../../helpers/stroage'

const PaymentDetails = () => {
  const { state: paymentInfo } = useLocation()
  const dataForPayment: any = localStorage.getItem('dataForOtherPayment')
  const storageInfo = JSON.parse(dataForPayment)
  const userInfo = StorageBox.retrieveUserData()
  const convertDate = new Date(paymentInfo.created_at)
  const couponDate = moment(convertDate)
  const modifiedDate = couponDate.format('MMMM Do YYYY, h:mm')

  //   console.log(storageInfo)
  //   console.log(paymentInfo)
  return (
    <div className='h-screen overflow-hidden bg-neutral-50'>
      <div className='h-full mt-5 text-[#484442]'>
        <div className='flex items-center mb-6 mx-[20px]'>
          <div className='flex-[0.3]'>
            <button
              className='h-10 text-neutral-300 flex justify-center items-center'
              onClick={() => {
                window.location.replace('/home')
              }}
            >
              <ChevronLeftIcon className='h-6 w-6 font-bold' />
            </button>
          </div>
          <div className='flex-[0.7]'>
            <h4 className='text-header-4'>Coupon details</h4>
          </div>
        </div>
        <div className='mx-[20px]'>
          <div className='h-[86px] rounded-2xl bg-gradient-to-r from-[#FF0800] to-[#FFB080] mb-3 text-white flex justify-between items-center px-3 py-6'>
            <div className='flex gap-x-5 items-center'>
              <img src='/shell.png' alt='shell' />
              <div>
                <p className=' text-xs'>Fuel type</p>
                <p className=' font-bold text-lg'>
                  {storageInfo.fuel_type_code}
                </p>
              </div>
            </div>
            <p className=' text-2xl font-extrabold'>
              GH&#8373;{storageInfo.price}
            </p>
          </div>
          <p className=' font-semibold mb-3'>Overview</p>
          <div className='flex justify-between mb-2'>
            <p>Purchased by</p>
            <div>
              <p className='font-semibold'>
                {userInfo?.user?.first_name} {userInfo?.user?.last_name}
              </p>
              <p>{userInfo?.user?.phone}</p>
            </div>
          </div>
          {paymentInfo?.users ? (
            <></>
          ) : (
            <div className='flex justify-between mb-2'>
              <p>Bought for</p>
              <div>
                <p className='font-semibold'>
                  {storageInfo.first_name} {storageInfo.last_name}
                </p>
                <p>{storageInfo.phone}</p>
              </div>
            </div>
          )}

          <div className='flex justify-between items-center mb-2'>
            <p>Created on</p>
            <p className='font-semibold'>{modifiedDate}</p>
          </div>
        </div>
        {paymentInfo?.users && (
          <>
            <hr className='border border-gray-400 mb-[50px]' />
            <div className=' mx-5'>
              <p className='font-bold mb-3'>Owners</p>
              <div>
                <div>
                  {paymentInfo?.users?.map((user: any, index: number) => (
                    <>
                      {' '}
                      <p className='font-semibold mb-2'>User {index + 1}</p>
                      <div className='flex justify-between mb-2'>
                        <div>
                          <p className='font-semibold'>
                            {user.first_name}
                            {user.last_name}
                          </p>
                          <p>{user.phone}</p>
                        </div>
                        <p>GH&#8373;{user.price}</p>
                      </div>
                      <hr className='border border-gray-400' />
                    </>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default PaymentDetails
