// import fuelLogo from '../images/fuel-nozzle.png';
import { useState, Fragment } from 'react'
import ChooseDrawer from '../pages/home/chooseDrawer'

const FLoatingAction = () => {
  const [openBottom, setOpenBottom] = useState(false)

  const openDrawerBottom = () => {
    setOpenBottom(!openBottom)
  }

  return (
    <>
      <button
        onClick={() => openDrawerBottom()}
        className='dark:shadow-[#4c4c4c] dark:shadow fixed bottom-[3rem] right-6 p-4 rounded-[16px] gap-[8px] bg-white flex justify-center items-center shadow-lg dark:bg-dark-100 dark:text-white'
      >
        {/* <img src={fuelLogo} alt="logo" className='w-10 h-10' /> */}
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
        >
          <path
            d='M16.2498 10.4168C16.2498 9.26683 17.1832 8.3335 18.3332 8.3335V7.50016C18.3332 4.16683 17.4998 3.3335 14.1665 3.3335H5.83317C2.49984 3.3335 1.6665 4.16683 1.6665 7.50016V7.91683C2.8165 7.91683 3.74984 8.85016 3.74984 10.0002C3.74984 11.1502 2.8165 12.0835 1.6665 12.0835V12.5002C1.6665 15.8335 2.49984 16.6668 5.83317 16.6668H14.1665C17.4998 16.6668 18.3332 15.8335 18.3332 12.5002C17.1832 12.5002 16.2498 11.5668 16.2498 10.4168Z'
            stroke='#A53D00'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M8.3335 3.3335L8.3335 16.6668'
            stroke='#A53D00'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-dasharray='5 5'
          />
        </svg>
        <div className='text-primary text-center leading-3 text-[16px]'>
          Buy coupon
        </div>
      </button>

      {openBottom ? (
        <ChooseDrawer
          openDrawer={openBottom}
          handleOpenDrawer={() => openDrawerBottom()}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default FLoatingAction
