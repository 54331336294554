import { ChevronLeftIcon } from '@heroicons/react/24/outline'
// import { Option, Select } from '@material-tailwind/react'
import { useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
// import PhoneInput, { Value } from 'react-phone-number-input'
import BaseService from '../helpers/baseServices'
import { global_variables } from '../helpers/globalVariables'
import Button from '../components/Button'
import { debounce } from 'lodash'
import ShowToast from '../components/alerts/all_toasts'
import { Spinner } from '@material-tailwind/react'
// import { PhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'
import ShareOtherUsers from '../components/ShareOtherUsers'
import { StorageBox } from '../helpers/stroage'
import { notification, NotificationArgsProps } from 'antd'
import SharingSuccess from '../components/SharingSuccess'
import ProfileModal from '../components/ProfileModal'

type NotificationPlacement = NotificationArgsProps['placement']

const CouponSharing = () => {
  const [step, setStep] = useState(1)
  const [shareSuccessful, setShareSuccessful] = useState(false)
  const [users, setUsers] = useState<any>([
    {
      firstname: '',
      lastname: '',
      id: Date.now().toString(),
      nameError: false,
      phoneError: false,
      phone: '',
      busy: false,
      amount: ''
    }
  ])

  const navigate = useNavigate()
  const { id } = useParams()
  const location = useLocation()
  const coupon = location.state

  const [isBusy, setIsBusy] = useState(false)

  const userInfo = StorageBox.retrieveUserData()
  const [api, contextHolder] = notification.useNotification()

  const openNotification = (placement: NotificationPlacement) => {
    api.info({
      type: 'error',
      message: 'Transaction Failed',
      description: "User already added / can't add yourself",
      placement
    })
  }

  // handleOnPhoneChange
  const phoneHandler = debounce((pho: string, id: string) => {
    const usersCoppy = [...users]
    let formatPhoneNumber = pho?.replace(/\s+/g, '')

    if (formatPhoneNumber === userInfo?.user?.phone) {
      return openNotification('topRight')
    }

    const alreadyAddedUser = usersCoppy.find(
      (user) => user.phone === formatPhoneNumber
    )
    if (usersCoppy.length > 1 && alreadyAddedUser) {
      return openNotification('topRight')
    }
    let data = {
      phone: formatPhoneNumber
    }

    if (pho && pho.length >= 10) {
      setIsBusy(true)
      BaseService.post_api_auth(`${global_variables.find_user_by_phone}`, data)
        .then((response) => {
          const varifiedUsers = usersCoppy.map((user) =>
            user.id === id
              ? {
                  ...user,
                  firstname: response?.data?.payload?.first_name,
                  lastname: response?.data?.payload?.last_name,
                  phone: formatPhoneNumber
                }
              : user
          )
          setUsers(varifiedUsers)
          setIsBusy(false)
        })
        .catch((error) => {
          // console.log(error)
          ShowToast.error_toast(
            error?.response?.data?.message || error?.message
          )
          const errorUsers = usersCoppy.map((user) =>
            user.id === id
              ? {
                  ...user,
                  firstname: '',
                  lastname: '',
                  phoneError: true
                }
              : user
          )
          setUsers(errorUsers)
          setIsBusy(false)
        })
    }
  }, 2000)

  const addUserHandler = () => {
    const usersCopy = [...users]
    setUsers([
      ...usersCopy,
      {
        firstname: '',
        lastname: '',
        id: Date.now().toString(),
        nameError: false,
        phoneError: false,
        phone: '',
        busy: false
      }
    ])
  }

  const removeUserHandler = (id: string) => {
    const usersCopy = [...users]
    const filteredUsers = usersCopy.filter((user) => user.id !== id)
    setUsers(filteredUsers)
  }

  const continueStepOne = () => {
    let detailsError = false
    const usersCoppy = [...users]
    usersCoppy.forEach((user) => {
      if (
        user.firstname === '' ||
        user.lastname === '' ||
        parseFloat(user.amount).toFixed(2) >
          parseFloat(coupon.amount_left).toFixed(2) ||
        user.amount === '' ||
        totalSharedAmount > coupon.amount_left.toFixed(2)
      ) {
        detailsError = true
      }
    })
    if (detailsError) {
      ShowToast.error_toast('Details Error')
    } else {
      setStep(2)
    }
  }

  const shareAmountHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    // const regExp = /^[0-9]*$/
    const regExp = /^[+]?((\d+(\.\d*)?)|(\.\d+))*$/
    //console.log(e.target.value)
    if (regExp.test(e.target.value)) {
      const usersCoppy = [...users]
      const updatedUsers = usersCoppy.map((user) =>
        user.id === id
          ? {
              ...user,
              amount: e.target.value
            }
          : user
      )
      setUsers(updatedUsers)
    }
  }

  let totalSharedAmount = 0

  users.forEach((user: any) => {
    totalSharedAmount += parseFloat(user.amount)
  })

  const handleShare = () => {
    setIsBusy(true)

    const data = users.map((user: any) => ({
      amount_to_share: parseFloat(user.amount).toFixed(2),
      phone: user.phone,
      first_name: user.firstname,
      last_name: user.lastname
    }))

    const shareWith = {
      destinations: data
    }

    // return console.log(shareWith)

    BaseService.post_api(`/coupons/${id}/share`, shareWith)
      .then((response) => {
        if (response?.data.message === 'OK') {
          setShareSuccessful(true)
        }

        setIsBusy(false)
      })
      .catch((error) => {
        // console.log(error)
        ShowToast.error_toast(error?.response?.data?.message || error?.message)
        setIsBusy(false)
      })
  }

  const closeSuccessAlert = () => {
    setShareSuccessful(false)
    navigate(`/coupons/${id}`)
  }

  return (
    <>
      {contextHolder}
      <div
        className={`h-screen ${users.length > 1 ? 'overflow-scroll' : 'overflow-hidden'} bg-neutral-50 dark:bg-dark-100`}
      >
        <div className='h-full mx-[20px] mt-5 relative'>
          {step === 1 && (
            <>
              <div className='flex justify-between items-center mb-3'>
                <div className='flex-[0.3]'>
                  <button
                    className='h-10 text-neutral-300 flex justify-center items-center'
                    onClick={() => navigate(-1)}
                  >
                    <ChevronLeftIcon className='h-6 w-6 font-bold' />
                  </button>
                </div>
                <div className='flex-[0.7]'>
                  <h4 className='text-header-4'>Share</h4>
                </div>
              </div>
              <div className='text-[#484442]'>
                <h3 className=' font-bold mb-2'>
                  Share coupons with friends and family
                </h3>
                <p>
                  Easily share your coupon with friends and family on the go.
                </p>
              </div>
              {users.map((user: any, index: any) => (
                <ShareOtherUsers
                  key={user.id}
                  firstname={user.firstname}
                  lastname={user.lastname}
                  handleOnPhoneChange={(val: any) => phoneHandler(val, user.id)}
                  addUser={addUserHandler}
                  removeUser={() => removeUserHandler(user.id)}
                  nameError={user.nameError}
                  phoneError={user.nameError}
                  phoneNumber={user.phone}
                  isBusy={user.busy}
                  remove={users.length > 0 && index + 1 < users.length}
                  user={index}
                  amount={user.amount}
                  shareError={
                    user.amount &&
                    parseFloat(user.amount).toFixed(2) >
                      parseFloat(coupon.amount_left).toFixed(2)
                      ? true
                      : false
                  }
                  handleAmount={(e: React.ChangeEvent<HTMLInputElement>) =>
                    shareAmountHandler(e, user.id)
                  }
                />
              ))}
            </>
          )}
          {step === 2 && (
            <>
              <div className='flex justify-between items-center'>
                <div className='flex-[0.3]'>
                  <button
                    className='h-10 text-neutral-300 flex justify-center items-center'
                    onClick={() => setStep(1)}
                  >
                    <ChevronLeftIcon className='h-6 w-6 font-bold' />
                  </button>
                </div>
                <div className='flex-[0.7] mb-5'>
                  <h4 className='text-header-4'>Share</h4>
                </div>
              </div>
              {shareSuccessful ? (
                <ProfileModal
                  title=''
                  showModal={shareSuccessful}
                  removeModal={closeSuccessAlert}
                >
                  <div className=' h-full flex justify-center items-center'>
                    <SharingSuccess />
                  </div>
                </ProfileModal>
              ) : (
                <div>
                  <h3 className=' font-bold mb-5'>Share your coupon with:</h3>
                  {users.map((user: any, index: number) => (
                    <div key={user.id}>
                      <div className='flex justify-between items-center text-[#484442] mb-1'>
                        <h3 className=' font-semibold'>User {index + 1}</h3>
                        <h3
                          className='text-red-700 cursor-pointer'
                          onClick={() => setStep(1)}
                        >
                          edit
                        </h3>
                      </div>
                      <div className='flex justify-between'>
                        <div>
                          <p>
                            {user.firstname} {user.lastname}
                          </p>
                          <p>{user.phone}</p>
                        </div>
                        <p>GH&#8373;{parseFloat(user.amount).toFixed(2)}</p>
                      </div>
                      <hr className='border border-gray-500 my-3' />
                    </div>
                  ))}
                  {step === 2 && (
                    <div className=' absolute w-full bottom-10'>
                      <Button width='w-full' onClick={handleShare}>
                        {isBusy ? (
                          <div className='w-full flex justify-center items-center'>
                            <Spinner />
                          </div>
                        ) : (
                          <span>Share</span>
                        )}
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </>
          )}

          <div className='mt-[50px] flex justify-center items-center'>
            {step === 1 && (
              <Button width='w-full' onClick={continueStepOne}>
                {isBusy ? (
                  <div className='w-full flex justify-center items-center'>
                    <Spinner />
                  </div>
                ) : (
                  <span>Continue</span>
                )}
              </Button>
            )}
            {/* {step === 2 && (
              <Button width='w-full' onClick={handleShare}>
                Share
              </Button>
            )} */}
          </div>
        </div>
      </div>
    </>
  )
}

export default CouponSharing
