export const global_variables = {
  // auth routes
  signinRoute: '/auth/login',

  get_stats: '/me/stats',
  get_coupons: '/me/coupons',
  get_one_coupon: '/coupons',
  create_coupon: '/coupons', // create coupons for self &  another single person
  create_coupon_others: '/create-coupons-for-others', // create coupons for other users
  pay_coupon_others: '/coupons/pay', // pay coupons for other users
  get_companies: '/companies',
  get_providers: '/providers',
  wallets_route: '/me/wallets',
  get_tranasaction: '/transactions',
  find_user_by_phone: '/v2/find-by-phone',
  get_fuel_types: '/fuel-types',
  get_fuel_stations: '/branches'
}
