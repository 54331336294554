import { Fragment } from 'react'
import { PaymentInfo } from '../../../../types'
import { useNavigate } from 'react-router-dom'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import Button from '../../../../components/Button'

type ResultType = 'SUCCESS' | 'FAILED'

const ResultsPage = ({
  type,
  // data,
  paymentInfo,
  retry
}: {
  type: ResultType
  // data: Coupon | null | undefined;
  paymentInfo: PaymentInfo | null | undefined
  retry?: () => void
}) => {
  const navigate = useNavigate()
  return (
    <Fragment>
      <div className='h-screen overflow-hidden bg-neutral-50'>
        <div className='h-full mx-[20px] mt-5'>
          <div className='flex items-center'>
            <div className='flex-[0.3]'>
              <button
                className='h-10 text-neutral-300 flex justify-center items-center'
                onClick={() => {
                  window.location.replace('/home')
                }}
              >
                <ChevronLeftIcon className='h-6 w-6 font-bold' />
              </button>
            </div>
            <div className='flex-[0.7]'>
              <h4 className='text-header-4'>Payment summary</h4>
            </div>
          </div>

          {type === 'SUCCESS' && (
            <div className='mt-10'>
              <div className='flex items-center justify-between'>
                <div className='text-[16px]'>Amount</div>
                <div className='text-[18px] font-bold'>
                  {paymentInfo?.amount?.currency}
                  {Intl.NumberFormat('en-EN').format(
                    Number(paymentInfo?.amount?.value)
                  )}
                </div>
              </div>
              <div className='flex items-center justify-between my-1'>
                <div className='text-[16px]'>Payment method</div>
                <div className='text-[18px] font-bold'>
                  {paymentInfo?.method?.name}
                </div>
              </div>
              <div className='flex items-center justify-between mb-8'>
                <div className='text-[16px]'>Reference</div>
                <div className='text-[18px] font-bold'>
                  {paymentInfo?.reference}
                </div>
              </div>
              <Button
                width='w-full'
                onClick={() =>
                  navigate(`/other-payment/details/${paymentInfo?.id}`, {
                    state: paymentInfo
                  })
                }
              >
                View details
              </Button>
            </div>
          )}
          {type === 'FAILED' && (
            <div className='mt-10'>
              <div className='flex items-center justify-between'>
                <div className='text-[16px]'>Amount</div>
                <div className='text-[18px] font-bold'>
                  {paymentInfo?.amount?.currency}
                  {Intl.NumberFormat('en-EN').format(
                    Number(paymentInfo?.amount?.value)
                  )}
                </div>
              </div>
              <div className='flex items-center justify-between my-1'>
                <div className='text-[16px]'>Payment method</div>
                <div className='text-[18px] font-bold'>
                  {paymentInfo?.method?.name}
                </div>
              </div>
              <div className='flex items-center justify-between my-1'>
                <div className='text-[16px]'>Reference</div>
                <div className='text-[18px] font-bold'>
                  {paymentInfo?.reference}
                </div>
              </div>
              <div className='flex items-center justify-between mb-8'>
                <div className='text-[16px]'>Remarks</div>
                <div className='text-[18px] font-bold'>
                  {paymentInfo?.remarks}
                </div>
              </div>

              <Button
                onClick={() => {
                  if (retry) {
                    retry()
                  }
                }}
                width='w-full'
              >
                Try again
              </Button>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default ResultsPage
