import React from 'react'
import { Modal } from 'antd'

type ProfileImageModalProps = {
  children: React.ReactNode
  showModal: boolean
  removeModal: () => void
  title: string
}

const ProfileModal = ({
  children,
  showModal,
  removeModal,
  title
}: ProfileImageModalProps) => {
  return (
    <div className='rounded-3xl'>
      <Modal
        title={
          <p className=' font-bold text-lg text-[#484442] mb-4'>{title}</p>
        }
        centered
        open={showModal}
        onCancel={removeModal}
        footer={null}
      >
        <div className=' w-full'>{children}</div>
      </Modal>
    </div>
  )
}

export default ProfileModal
