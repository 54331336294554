type SlideProps = {
  title: string
  content: string
  image: string
}

const Slide = ({ title, content, image }: SlideProps) => {
  return (
    <div className='w-full text-center mt-[121px] flex flex-col items-center'>
      <h3 className=' font-bold text-lg w-[243px]'>{title}</h3>
      <p className='w-[243px] mt-6 text-sm mb-2'>{content}</p>
      <img src={image} className='w-full h-[312px]' alt='banner' />
    </div>
  )
}

export default Slide
