type MergeModalProps = {
  handleMerge: () => void
  selectedCoupons: any[]
}

function MergeModal({ handleMerge, selectedCoupons }: MergeModalProps) {
  let couponAmount = 0
  if (selectedCoupons.length > 0) {
    selectedCoupons.forEach((coupon: any) => {
      couponAmount += coupon.amount_left
    })
  }

  return (
    <div className='rounded-t-[30px] shadow-2xl shadow-black dark:bg-dark-100 h-[80px] sticky bottom-0'>
      <div className='px-5 py-4 h-full rounded-t-[30px] bg-white'>
        <div className='flex w-full justify-between items-center'>
          <p>
            Selected ({selectedCoupons.length})
            {selectedCoupons.length > 0 && (
              <span> - GH&#8373;{(couponAmount * 1).toFixed(2)}</span>
            )}
          </p>

          <button
            className={`font-medium ${selectedCoupons.length > 1 ? 'text-[#A53D00]' : 'text-[#FFB080]'} cursor-pointer`}
            disabled={selectedCoupons.length <= 1}
            onClick={handleMerge}
          >
            Merge
          </button>
        </div>
      </div>
    </div>
  )
}

export default MergeModal
