type ArchiveModalProps = {
  handleArchive: () => void
  selectedCoupons: any[]
  title: string
  handleUnArchive: () => void
}

function ArchiveModal({
  handleArchive,
  selectedCoupons,
  title,
  handleUnArchive
}: ArchiveModalProps) {
  return (
    <div className='rounded-t-[30px] shadow-2xl shadow-black dark:bg-dark-100 h-[80px] sticky bottom-0'>
      <div className='px-5 py-4 h-full rounded-t-[30px] bg-white'>
        <div className='flex w-full justify-between items-center'>
          <p>Selected ({selectedCoupons.length})</p>
          {title === 'archive' ? (
            <button
              className={`font-medium ${selectedCoupons.length > 0 ? 'text-[#A53D00]' : 'text-[#FFB080]'} cursor-pointer`}
              disabled={selectedCoupons.length <= 0}
              onClick={handleUnArchive}
            >
              UnArchive
            </button>
          ) : (
            <button
              className={`font-medium ${selectedCoupons.length > 0 ? 'text-[#A53D00]' : 'text-[#FFB080]'} cursor-pointer`}
              disabled={selectedCoupons.length <= 0}
              onClick={handleArchive}
            >
              Archive
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default ArchiveModal
