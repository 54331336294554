// type SharingSuccessProps = {
//   handleClose: () => void
// }

function SharingSuccess() {
  return (
    <div className='p-2'>
      {/* <div className='flex justify-end' onClick={() => handleClose()}>
        <img src='/close-circle.png' alt='close' />
      </div> */}

      <div className='flex flex-col gap-y-1 items-center'>
        <img src='/copy-success.png' alt='success' />
        <h3 className=' font-bold text-sm'>Sharing successful</h3>
        <p className=' w-[268px] text-[#484442] text-center'>
          Your coupon has been shared successfully.
        </p>
      </div>
    </div>
  )
}

export default SharingSuccess
