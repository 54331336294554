import { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import BaseService from '../../helpers/baseServices'
import { RouteConstants } from '../../helpers/RouteConstants'
import moment from 'moment'

type Props = {}

const Notification = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [notificationEvents, setNotificationEvents] = useState<any>([])

  const navigate = useNavigate()

  const getUserNotifications = async () => {
    setIsLoading(true)

    try {
      const res = await BaseService.get_api(
        `${RouteConstants.MY_COUPON_EVENTS}`
      )

      setNotificationEvents(res.data?.payload?.items)
      setIsLoading(false)
      //return res;
    } catch (e) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getUserNotifications()
  }, [])
  return (
    <Fragment>
      <div className='h-screen overflow-hidden bg-neutral-50 dark:bg-dark-100'>
        <div className='overflow-y-auto h-full mt-5'>
          <div className='flex items-center mx-[20px]'>
            <div className='flex-[0.3] '>
              <button
                className='h-10 text-neutral-300 flex items-center'
                onClick={() => navigate(-1)}
              >
                <ChevronLeftIcon className='h-6 w-6 font-bold' />
              </button>
            </div>
            <div className='flex-[0.7]'>
              <h4 className='text-header-4'>Notifications</h4>
            </div>
          </div>
          <div className='mt-5'>
            {isLoading ? (
              <div className='mx-[20px]'>
                {['', '', '', '', ''].map((dd, i) => (
                  <div
                    key={i}
                    className='w-full bg-white shadow-lg p-5 rounded-[10px] flex justify-between mb-3 animate-pulse'
                  >
                    <div>
                      <p className='font-medium text-transparent bg-gray-400 h-3 w-fit rounded-lg'>
                        GHS 100.00
                      </p>
                      <p className='text-sm text-transparent bg-gray-400 h-2 w-fit mt-2 rounded-lg'>
                        Self
                      </p>
                    </div>

                    <div>
                      <p className='text-xs font-medium text-transparent bg-gray-400 h-3 w-fit rounded-lg'>
                        Do MMM YYYY (hh:mm A)
                      </p>
                      <p className='text-sm text-transparent bg-gray-400 h-2 w-fit mt-2 rounded-lg'>
                        Not Paid
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <>
                {notificationEvents.length > 0 ? (
                  <div>
                    {notificationEvents.map((item: any, index: number) => (
                      <div
                        className='py-3 border-b border-white px-[20px] w-full flex items-center gap-2'
                        key={index}
                      >
                        <div>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                          >
                            <path
                              d='M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2Z'
                              stroke='#FF9655'
                              stroke-width='1.5'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                            <path
                              d='M12 16V11'
                              stroke='#FF9655'
                              stroke-width='1.5'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                            <path
                              d='M12.0059 8H11.9969'
                              stroke='#FF9655'
                              stroke-width='2'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                          </svg>
                        </div>
                        <div>
                          <div className='leading-3 text-neutral-300 text-[12px]'>
                            {item?.comment}
                          </div>
                          <div className='pt-1 text-xs text-neutral-300'>
                            {moment(item?.created_at).format(
                              'MMMM Do, YYYY, h:mm a'
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                ) : (
                  <div className='flex-[1] flex justify-center w-full items-center h-full'>
                    <div className='text-neutral-300 text-center'>
                      No available coupon notifications at the moment.
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Notification
