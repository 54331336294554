import { useState } from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Spinner,
} from "@material-tailwind/react";
import { useParams } from "react-router-dom";
import PhoneInput, { Value } from "react-phone-number-input";
import ShowToast from "../../components/alerts/all_toasts";
import { RouteConstants } from "../../helpers/RouteConstants";
import BaseService from "../../helpers/baseServices";

interface modalProps {
  openModal: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  couponId?: string;
}

const TransferCouponModal = ({
  openModal,
  handleOpenModal,
  handleCloseModal,
  couponId,
}: modalProps) => {
  const { id } = useParams();
  const [phoneNum, setPhoneNum] = useState<Value | undefined>("");
  const [isPhoneError, setIsPhoneError] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const coupon_id = couponId || id;

  //handleTransfer
  const handleTransfer = async () => {
    let formatPhoneNumber = phoneNum?.replace(/\s+/g, "");
    if (!phoneNum) {
      setIsPhoneError(true);
    } else {
      let data = {
        phone: formatPhoneNumber,
      };
      setIsBusy(true);
      try {
        await BaseService.put_api(
          `${RouteConstants.COUPONS_ROUTE}/${coupon_id}/transfer`,
          data
        );
        ShowToast.success_toast("Coupon has been successfully transfered");
        window.location.reload()
      } catch (error: any) {
        console.error(error);
        ShowToast.error_toast(error?.response?.data?.message);
        handleCloseModal();
        setIsBusy(false);
      }
    }
  };

  return (
    <>
      <Dialog open={openModal} handler={handleOpenModal} placeholder="">
        <DialogHeader placeholder="">Transfer Coupon</DialogHeader>
        <DialogBody divider placeholder="">
          <div className="flex flex-col relative">
            <small className={`${isPhoneError ? "text-red-500" : ""} mb-2`}>
              Enter mobile number
            </small>
            <PhoneInput
              className="rounded-[5px] w-full border-[1px] border-gray-500 h-[40px] px-2 bg-white"
              defaultCountry="GH"
              inputStyle={{ width: "100%" }}
              value={phoneNum}
              onChange={(phone) => setPhoneNum(phone)}
              disabled={isBusy}
              numberInputProps={{
                className: "focus:outline-none",
              }}
            />
          </div>
        </DialogBody>
        <DialogFooter placeholder="">
          <div className="flex items-center gap-5 w-full">
            <button
              className="flex justify-center items-center py-2 rounded-[30px] text-white bg-gradient-to-r from-orange-700 to-orange-800 font-semibold w-full disabled:cursor-not-allowed"
              onClick={handleCloseModal}
              disabled={isBusy}
            >
              Cancel
            </button>
            <button
              className="flex justify-center items-center py-2 rounded-[30px] text-white bg-gradient-to-r from-orange-700 to-orange-800 font-semibold w-full disabled:cursor-not-allowed"
              onClick={() => handleTransfer()}
              disabled={isBusy}
            >
              {isBusy ? <Spinner color="red" /> : "Proceed"}
            </button>
          </div>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default TransferCouponModal;
