import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { Dialog, Option, Select, Spinner } from '@material-tailwind/react'
import { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BaseService from '../../../../helpers/baseServices'
import { global_variables } from '../../../../helpers/globalVariables'
// import PhoneInput, { Value } from 'react-phone-number-input'
import { PhoneInput } from 'react-international-phone'
import { StorageBox } from '../../../../helpers/stroage'
import {
  getTransactionStatus,
  toTwoDecimalPlaces
} from '../../../../utils/funtions'

import ErrorComponent from '../errorComponent'
import Button from '../../../../components/Button'
import ShowToast from '../../../../components/alerts/all_toasts'
import CreatingCoupon from '../creatingCoupon'
import ProcessingTransaction from '../processingTransaction'
import ResultsPage from './ResultsPage'
import { Coupon, PaymentInfo } from '../../../../types'

const PaymentOption = () => {
  const navigate = useNavigate()
  const [step, setStep] = useState(1)
  const [dataFromFirst, setDataFromFirst] = useState<any>(null)
  const [phoneNum, setPhoneNum] = useState('')
  const [accName, setAccName] = useState('')
  const [isError, setIsError] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [allProviders, setAllProviders] = useState([])
  const [selectedProvider, setSelectedProvider] = useState('')
  const [allWallets, setAllWallets] = useState([])
  const [thePin, setThePin] = useState('')
  const [loggedUser, setLoggedUser] = useState<any>(null)

  const [couponData, setCouponData] = useState<Coupon | null>()
  const [paymentData, setPaymentData] = useState<PaymentInfo | null>()

  //processes
  const [isCreatingCoupon, setIsCreatingCoupon] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isFailed, setIsFailed] = useState(false)
  const [endPool, setEndPool] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)

  const handleClosePop = () => {
    setThePin('')
    setIsBusy(false)
  }
  //show error popup
  const [showErrorPop, setShowErrorPop] = useState(false)
  const [errorData, setErrorData] = useState<any>('')
  const handleErrorShow = () => {
    setShowErrorPop(true)
  }
  const handleCloseError = () => {
    setShowErrorPop(false)
    setThePin('')

    setIsBusy(false)
  }

  //get paymentProviders
  const fetchPaymentProviders = async () => {
    setIsFetching(true)
    try {
      const response = await BaseService.get_api(global_variables.get_providers)
      // console.log(response?.data)
      setAllProviders(response?.data?.payload?.items)
      setIsFetching(false)
    } catch (error) {
      // console.log(error)
    }
  }

  //get wallets
  const getWallets = () => {
    setIsFetching(true)
    BaseService.get_api(`/me/wallets`)
      .then((res) => {
        // console.log(res?.data?.payload)
        setAllWallets(res?.data?.payload?.items)
        setIsFetching(false)
      })
      .catch((err) => {
        // console.log(err)
      })
  }

  //handle create coupon
  const handleCreateCoupon = async () => {
    if (selectedProvider === 'KOST_WALLET') {
      if (!thePin) {
        setIsError(true)
        return
      }

      //create coupon

      //handlePopShow(null, null);

      setIsBusy(true)

      let data = {
        account_name: loggedUser?.user?.name,
        account_number: phoneNum,
        pin: thePin,
        payment_provider: selectedProvider,

        ...dataFromFirst
      }
      // console.log(data)
      try {
        setIsCreatingCoupon(true)
        const response = await BaseService.post_api(
          `${global_variables.create_coupon}`,
          data
        )
        if (response?.data?.payload?.payment === null) {
          setIsCreatingCoupon(false)
          handleClosePop()
          handleErrorShow()
          setErrorData(response?.data?.payload)
        } else {
          setPaymentData(response?.data?.payload?.payment)
          setCouponData(response?.data?.payload?.coupon)
          setIsCreatingCoupon(false)
          setIsProcessing(true)
          // console.log(response)
          getTransactionStatus(response?.data?.payload?.payment?.id)
            .then((transRes: any) => {
              // console.log(transRes)
              const statusCode = transRes?.data?.payload?.status?.code
              if (statusCode === 'SUCCESS') {
                setIsProcessing(false)
                ShowToast.success_toast('Success')

                setTimeout(() => {
                  //show coupon details
                  setIsSuccessful(true)
                  //setIsBusy(false);
                }, 800)
              } else if (statusCode === 'FAILED') {
                setIsProcessing(false)
                ShowToast.error_toast('Failed')
                setTimeout(() => {
                  setIsFailed(true)
                }, 800)
              } else {
                setIsProcessing(false)
                setEndPool(true)
                //setIsBusy(false);
                console.log('pool end')
              }
            })
            .catch((e) => {
              //handle error
            })
        }
      } catch (error: any) {
        // handleClosePop();
        // setErrorData(error);
        // console.log(error);
        // console.log('here...');
        //handleErrorShow();
        setIsBusy(false)
        ShowToast.error_toast(error?.response?.data?.message || error?.message)
      }
    } else {
      if (!phoneNum || !accName) {
        setIsError(true)
      }
      //create coupon
      let formatPhoneNumber = phoneNum?.replace(/\s+/g, '')
      //handlePopShow(null, null);

      setIsBusy(true)

      let data = {
        account_name:
          selectedProvider === 'KOST_WALLET' ? loggedUser?.user?.name : accName,
        account_number: formatPhoneNumber,
        pin: thePin,
        payment_provider: selectedProvider,
        phone: formatPhoneNumber,
        ...dataFromFirst
      }
      // console.log(data)
      try {
        setIsCreatingCoupon(true)
        const response = await BaseService.post_api(
          `${global_variables.create_coupon}`,
          data
        )
        if (response?.data?.payload?.payment === null) {
          setIsCreatingCoupon(false)
          handleClosePop()
          handleErrorShow()
          setErrorData(response?.data?.payload)
        } else {
          setPaymentData(response?.data?.payload?.payment)
          setCouponData(response?.data?.payload?.coupon)

          setIsCreatingCoupon(false)
          setIsProcessing(true)
          getTransactionStatus(response?.data?.payload?.payment?.id)
            .then((transRes: any) => {
              const statusCode = transRes?.data?.payload?.status?.code
              if (statusCode === 'SUCCESS') {
                setIsProcessing(false)
                ShowToast.success_toast('Success')

                setTimeout(() => {
                  //show coupon details
                  setIsSuccessful(true)
                  //setIsBusy(false);
                }, 800)
              } else if (statusCode === 'FAILED') {
                setIsProcessing(false)
                ShowToast.error_toast('Failed')
                setTimeout(() => {
                  setIsFailed(true)
                }, 800)
              } else {
                setIsProcessing(false)
                setEndPool(true)
                //setIsBusy(false);
                console.log('pool end')
              }
            })
            .catch((e) => {
              //handle error
            })
        }
      } catch (error: any) {
        // handleClosePop();
        // setErrorData(error);
        // console.log(error);
        // console.log('here...');
        //handleErrorShow();
        setIsBusy(false)
        ShowToast.error_toast(error?.response?.data?.message || error?.message)
        navigate('/self-fuel')
      }
    }

    // if (
    //   !selectedProvider ||
    //   !phoneNum ||
    //   (selectedProvider !== 'KOST_WALLET' && accName === '') ||
    //   (selectedProvider === 'KOST_WALLET' && thePin === '')
    // ) {
    //   setIsError(true);
    // } else {

    // }
  }

  useEffect(() => {
    const dataForPayment: any = localStorage.getItem('dataForPayment')
    const ddd = JSON.parse(dataForPayment)
    //console.log(ddd)
    setDataFromFirst(ddd)
    fetchPaymentProviders()
    getWallets()

    const userData = StorageBox.retrieveUserData()
    setLoggedUser(userData)
  }, [])

  return (
    <Fragment>
      <div className='h-screen overflow-hidden bg-neutral-50 dark:bg-dark-100'>
        {step === 1 ? (
          <div className='h-full mx-[20px] mt-5'>
            <div className='flex items-center'>
              <div className='flex-[0.3] '>
                <button
                  className='h-10 text-neutral-300 flex items-center'
                  onClick={() => navigate(-1)}
                >
                  <ChevronLeftIcon className='h-6 w-6 font-bold' />
                </button>
              </div>
              <div className='flex-[0.7]'>
                <h4 className='text-header-4'>Payment method</h4>
              </div>
            </div>

            <div className='mt-10 '>
              <div>
                <small
                  className={`${
                    isError
                      ? 'text-red-500 ml-1 mb-2'
                      : 'ml-1 mb-2 text-caption'
                  }`}
                >
                  Payment method
                </small>
                <Select
                  // label='Select payment method'
                  className='bg-white h-[50px]'
                  placeholder=''
                  onChange={(val: any) => {
                    setSelectedProvider(val)
                    setStep(2)
                  }}
                  disabled={isBusy || isFetching}
                  error={isError}
                >
                  {allProviders.map((provider: any, i) => (
                    <Option key={i} value={provider?.code}>
                      {provider?.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        ) : (
          <div className='h-full mx-[20px] mt-5'>
            <div className='flex items-center'>
              <div className='flex-[0.3]'>
                <button
                  className='h-10 w-10 text-neutral-300 flex justify-center items-center'
                  onClick={() => navigate(-1)}
                >
                  <ChevronLeftIcon className='h-6 w-6 font-bold' />
                </button>
              </div>
              <div className='flex-[0.7]'>
                <h4 className='text-header-4'>Payment details</h4>
              </div>
            </div>

            <div className='mt-10'>
              <div>
                <small
                  className={`${
                    isError
                      ? 'text-red-500 ml-1 mb-2'
                      : 'ml-1 mb-2 text-caption'
                  }`}
                >
                  Payment method
                </small>
                <Select
                  // label='Select payment method'
                  className='bg-white h-[50px]'
                  placeholder=''
                  onChange={(val: any) => {
                    setSelectedProvider(val)
                    setStep(2)
                  }}
                  disabled={isBusy || isFetching}
                  error={isError}
                >
                  {allProviders.map((provider: any, i) => (
                    <Option key={i} value={provider?.code}>
                      {provider?.name}
                    </Option>
                  ))}
                </Select>
              </div>
              {selectedProvider === 'KOST_WALLET' ? (
                <div className='mt-3'>
                  <small
                    className={`${
                      isError
                        ? 'text-red-500 ml-1 mb-2'
                        : 'ml-1 mb-2 text-caption'
                    }`}
                  >
                    Choose wallet
                  </small>
                  <Select
                    placeholder=''
                    label='Choose wallet'
                    className='bg-white h-[50px]'
                    onChange={(acc: any) => setPhoneNum(acc)}
                  >
                    {allWallets.map((wallet: any, i: number) => (
                      <Option key={i} value={wallet?.number}>
                        <p>Wallet ID: {wallet?.number}</p>
                        <small>
                          Balance: {wallet?.currency}{' '}
                          {toTwoDecimalPlaces(wallet?.balance?.value)}
                        </small>
                      </Option>
                    ))}
                  </Select>
                </div>
              ) : (
                <div className='mt-5'>
                  <small
                    className={`${
                      isError
                        ? 'text-red-500 ml-1 mb-2'
                        : 'ml-1 mb-2 text-caption'
                    }`}
                  >
                    Mobile Money Number
                  </small>
                  <PhoneInput
                    defaultCountry='gh'
                    hideDropdown
                    placeholder='+233 24 000 0000'
                    inputStyle={{
                      width: '100%',
                      height: '55px',
                      fontSize: '18px',
                      border: '1px solid #939291',
                      padding: '8px',
                      borderTopRightRadius: '8px',
                      borderBottomRightRadius: '8px'
                    }}
                    value={phoneNum}
                    onChange={(phone) => setPhoneNum(phone)}
                    disabled={isBusy}
                  />
                </div>
              )}

              {selectedProvider === 'KOST_WALLET' ? (
                <div className='mt-3'>
                  <small
                    className={`${
                      isError
                        ? 'text-red-500 ml-1 mb2'
                        : 'ml-1 mb-2 text-caption'
                    }`}
                  >
                    Login pin
                  </small>
                  <input
                    type='password'
                    className='input-container w-full'
                    placeholder='Enter pin'
                    value={thePin}
                    onChange={(e) => setThePin(e.target.value)}
                    disabled={isBusy}
                  />
                </div>
              ) : (
                <></>
              )}

              {selectedProvider === 'KOST_WALLET' ? (
                <></>
              ) : (
                <div className='mt-5'>
                  <small
                    className={`${
                      isError
                        ? 'text-red-500 ml-1 mb-2'
                        : 'ml-1 mb-2 text-caption'
                    }`}
                  >
                    Mobile Money Name
                  </small>
                  <input
                    className='input-container w-full'
                    placeholder='Enter name'
                    type='text'
                    value={
                      selectedProvider === 'KOST_WALLET'
                        ? loggedUser?.user?.name
                        : accName
                    }
                    onChange={(e) => {
                      const regExp = /^[a-zA-Z\s]*$/
                      if (regExp.test(e.target.value)) {
                        setAccName(e.target.value)
                      }
                    }}
                    disabled={isBusy || selectedProvider === 'KOST_WALLET'}
                    style={{ color: 'black' }}
                  />
                </div>
              )}
            </div>

            <div className='mt-[50px] flex justify-center items-center'>
              <Button
                width='w-full'
                onClick={() => handleCreateCoupon()}
                disabled={isBusy}
              >
                {isBusy ? <Spinner /> : <span>Buy coupon</span>}
              </Button>
            </div>
          </div>
        )}
      </div>

      <Dialog
        open={isBusy}
        handler={() => setIsBusy(false)}
        size='xxl'
        placeholder=''
      >
        {isCreatingCoupon && <CreatingCoupon />}
        {isProcessing && <ProcessingTransaction stillProcessing />}
        {endPool && <ProcessingTransaction stillProcessing={true} />}
        {isSuccessful && (
          <ResultsPage
            type='SUCCESS'
            data={couponData}
            paymentInfo={paymentData}
          />
        )}
        {isFailed && (
          <ResultsPage
            type='FAILED'
            retry={() => {
              setIsBusy(false)
              setIsFailed(false)
            }}
            data={couponData}
            paymentInfo={paymentData}
          />
        )}
      </Dialog>

      {/* show error pop up */}
      {showErrorPop ? (
        <ErrorComponent
          openErrorDrawer={showErrorPop}
          handleCloseErrorDrawer={handleCloseError}
          theError={errorData}
        />
      ) : (
        <></>
      )}
    </Fragment>
  )
}

export default PaymentOption
