import { useState } from 'react'
import { Carousel } from 'antd'
import { useNavigate } from 'react-router-dom'
import Slide from '../components/Slide'

const banners = [
  {
    id: 1,
    title: 'Convenient and secure fuel coupon purchase',
    content:
      'Easily buy any amount of fuel and securely make payment for coupons.',
    image: '/images/slideImg-1.png'
  },
  {
    id: 2,
    title: 'Share fuel coupons',
    content:
      'Transfer purchased fuel coupons to friends, family, or colleagues with just a few taps.',
    image: '/images/slideImg-2.png'
  },
  {
    id: 3,
    title: 'Hassle-Free Redemption',
    content:
      'No need to fumble with cards or cash, validate your fuel coupons seamlessly at the pump.',
    image: '/images/slideImg-3.png'
  }
]

const Onboard = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const router = useNavigate()

  const handleDotClicked = (index: number) => {
    setCurrentSlide(index)
  }

  return (
    <div className='relative'>
      <Carousel
        autoplay
        effect='fade'
        speed={1500}
        afterChange={(slide) => setCurrentSlide(slide)}
      >
        {banners.map((banner) => (
          <Slide key={banner.id} {...banner} />
        ))}
      </Carousel>
      <div className='flex mb-[75px] gap-2 justify-center'>
        {banners.map((_banner, index) => (
          <span
            className={`w-[10px] h-[10px] rounded-[50%] cursor-pointer ${
              currentSlide === index ? 'bg-[#A53D00]' : 'bg-gray-400'
            }`}
            key={index}
            onClick={() => handleDotClicked(index)}
          ></span>
        ))}
      </div>
      <div className=' mx-5'>
        <button
          className=' rounded-lg p-4 bg-[#A53D00] text-white w-full mb-6'
          onClick={() => router('/signup')}
        >
          Get started
        </button>

        <p className=' text-gray-700'>
          Already a user?{' '}
          <span
            className='text-[#A53D00] cursor-pointer'
            onClick={() => router('/signin')}
          >
            Sign In
          </span>
        </p>
      </div>
    </div>
  )
}

export default Onboard
