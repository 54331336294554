import { useContext } from 'react'
import { DrawerContext } from '../../components/Providers'
import CustomNavigation from '../../components/CustomNavigation'
import CouponsScreen from './coupons'
// import UserWallet from '../../components/userWallet'
import FLoatingAction from '../../components/floating-action'
import Header from '../../components/Header'
import SideBar from '../../components/SideBar'

const HomeScreen = () => {
  const ctx = useContext(DrawerContext)

  const showDrawer = () => {
    ctx.toggleShow(true)
  }

  const onClose = () => {
    ctx.toggleShow(false)
  }
  return (
    <div className='w-full dark:bg-dark-100 dark:text-white'>
      <SideBar closeDrawer={onClose} show={ctx.show}>
        <CustomNavigation activeIndex={0} />
      </SideBar>

      <Header title='Home' toggleMenu={showDrawer} page='Home' />
      <div className='bg-neutral-50 relative h-screen overflow-hidden px-5 dark:bg-dark-100 dark:text-white'>
        {/* <UserWallet /> */}
        <div className='h-full w-full overflow-hidden'>
          {/* main content */}
          <div className="h-screen overflow-y-auto">
            <CouponsScreen />
          </div>
        </div>
        <FLoatingAction />
      </div>
    </div>
  )
}

export default HomeScreen;
