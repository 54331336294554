import { ChevronRightIcon } from '@heroicons/react/24/outline'
import moment from 'moment'

type DiscountCardProps = {
  coupon: any
  handleSelect: () => void
}

function DiscountCard({ coupon, handleSelect }: DiscountCardProps) {
  const convertDate = new Date(coupon?.created_at)
  const couponDate = moment(convertDate)
  const modifiedDate = couponDate.format('MMMM Do YYYY, h:mm')

  return (
    <div onClick={handleSelect} className=' mb-5'>
      <div className=' flex justify-between items-center cursor-pointer mb-2'>
        <div className='flex gap-x-2 items-center'>
          <svg
            width='32'
            height='32'
            viewBox='0 0 32 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M15.9998 29.3334C23.3636 29.3334 29.3332 23.3639 29.3332 16.0001C29.3332 8.63628 23.3636 2.66675 15.9998 2.66675C8.63604 2.66675 2.6665 8.63628 2.6665 16.0001C2.6665 23.3639 8.63604 29.3334 15.9998 29.3334Z'
              stroke='#484442'
              stroke-width='1.5'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
            <path
              d='M12 20L20 12'
              stroke='#484442'
              stroke-width='1.5'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
            <path
              d='M19.3258 19.3334H19.3378'
              stroke='#484442'
              stroke-width='2'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
            <path
              d='M12.6593 12.6667H12.6713'
              stroke='#484442'
              stroke-width='2'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>

          <p className=' text-[#484442] text-xs'>
            GH&#8373;{coupon?.amount} discount earned on
            <br /> GH&#8373;{coupon?.coupon?.price} coupon purchased
            <br />
            <span className=' text-[#939291] text-[10px]'>{modifiedDate}</span>
          </p>
        </div>
        <ChevronRightIcon className='h-6 w-6 font-bold' />
      </div>
      <hr className=' border border-white' />
    </div>
  )
}

export default DiscountCard
