import { Modal } from 'antd'

type MergePromptTypes = {
  coupons: any[]
  openPrompt: boolean
  isMerging: string
  acceptMergeHandler: () => void
  cancelMergeHandler: () => void
  mergeCouponDetails: () => void
}

function MergePrompt({
  acceptMergeHandler,
  cancelMergeHandler,
  coupons,
  openPrompt,
  isMerging,
  mergeCouponDetails
}: MergePromptTypes) {
  let couponAmount = 0
  if (coupons.length > 0) {
    coupons.forEach((coupon: any) => {
      couponAmount += coupon.amount_left
    })
  }

  let content = (
    <div>
      <p className='text-sm text-[#484442] w-[268px] mb-5'>
        Merging {coupons.length} coupons will create a new coupon worth
        GH&#8373;{(couponAmount * 1).toFixed(2)}{' '}
      </p>
      <div className='flex gap-x-3 justify-end items-center text-xs'>
        <button className='text-[#484442]' onClick={cancelMergeHandler}>
          Cancel
        </button>
        <button className=' text-[#F15B50]' onClick={acceptMergeHandler}>
          Merge coupons
        </button>
      </div>
    </div>
  )

  if (isMerging === 'merging') {
    content = (
      <div className='flex flex-col items-center justify-center'>
        <div className='loader mb-5'></div>
        <p>Merging {coupons.length} coupons</p>
      </div>
    )
  }

  if (isMerging === 'merged') {
    content = (
      <div className='flex flex-col items-center justify-center'>
        <svg
          width='32'
          height='31'
          viewBox='0 0 32 31'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect
            x='1.58008'
            y='1.5'
            width='28.84'
            height='27.5781'
            rx='13.789'
            stroke='#2ABB7F'
            stroke-width='3'
          />
          <path
            d='M8.08008 15.289L13.3691 20.5781L23.9201 10'
            stroke='#2ABB7F'
            stroke-width='2.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>
        <p className='text-[#2ABB7F] mb-6'>Coupons merged successfully</p>
        <p
          className='text-[#A53D00] cursor-pointer'
          onClick={mergeCouponDetails}
        >
          View coupon
        </p>
      </div>
    )
  }

  return (
    <Modal
      title={
        <p className=' font-semibold text-sm text-[#484442]'>
          {isMerging === 'merged' ? ' ' : 'Merge coupons'}
        </p>
      }
      open={openPrompt}
      onCancel={cancelMergeHandler}
      footer={null}
      centered
    >
      {content}
    </Modal>
  )
}

export default MergePrompt
