import React, { createContext, useState, useEffect } from 'react'

export const DrawerContext = createContext({
  show: false,
  toggleShow: (visible: boolean) => {}
})

export const ThemeContext = createContext({
  darkMode: false,
  changeDarkMode: (value: boolean) => {}
})

type ProviderProps = {
  children: React.ReactNode
}

const Providers = ({ children }: ProviderProps) => {
  const [toggle, setToggle] = useState(false)
  const [darkMode, setDarkMode] = useState(false)

  useEffect(() => {
    const isDarkMode = localStorage.getItem('darkMode')
    if (isDarkMode === 'true') {
      setDarkMode(true)
    } else if (isDarkMode === 'false') {
      setDarkMode(false)
    } else {
      setDarkMode(false)
    }
  }, [])

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [darkMode])

  return (
    <DrawerContext.Provider value={{ show: toggle, toggleShow: setToggle }}>
      <ThemeContext.Provider
        value={{ darkMode, changeDarkMode: (v: boolean) => setDarkMode(v) }}
      >
        {children}
      </ThemeContext.Provider>
    </DrawerContext.Provider>
  )
}

export default Providers
