import React from 'react'

const LoadingCoupons = () => {
  return (
    <div className='w-full bg-white shadow-lg p-5 rounded-[10px] flex justify-between mb-3 animate-pulse'>
      <div>
        <p className='font-medium text-transparent bg-gray-400 h-3 w-fit rounded-lg'>
          GHS 100.00
        </p>
        <p className='text-sm text-transparent bg-gray-400 h-2 w-fit mt-2 rounded-lg'>
          Self
        </p>
      </div>

      <div>
        <p className='text-xs font-medium text-transparent bg-gray-400 h-3 w-fit rounded-lg'>
          Do MMM YYYY (hh:mm A)
        </p>
        <p className='text-sm text-transparent bg-gray-400 h-2 w-fit mt-2 rounded-lg'>
          Not Paid
        </p>
      </div>
    </div>
  )
}

export default LoadingCoupons
