import Items from './Items'

import { parse } from 'querystring'

const CouponItems = () => {
  const title = parse(
    window.location && window.location.search.replace('?', '')
  )

  return <Items title={title.q} />
}

export default CouponItems
