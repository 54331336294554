import { Fragment, useEffect, useState } from 'react'
import processingGif from '../../../images/processingGif.gif'
import Button from '../../../components/Button'

const ProcessingTransaction = ({
  stillProcessing = false
}: {
  stillProcessing: boolean
}) => {
  const [counter, setCounter] = useState(30)
  useEffect(() => {
    const timer: any =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    return () => clearInterval(timer)
  }, [counter])

  return (
    <Fragment>
      {stillProcessing ? (
        <>
          <div className='flex min-h-screen flex-1 flex-col justify-center items-center bg-neutral-50 px-[20px]'>
            <div>
              <img className='w-[102px] h-[110px]' src={processingGif} alt='' />
            </div>
            <div className='mt-[48px] text-caption text-center w-[275px]'>
              Payment is being processed. You will recieve a prompt to enter
              your pin to complete this transaction.
            </div>
            <div className='text-center my-7 w-full'>{counter}s</div>
            <Button
              onClick={() => {
                window.location.replace('/home')
              }}
              width='w-full'
              className=' bg-neutral-50 text-[#A53D00] px-8 py-4'
            >
              Cancel
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className='flex min-h-screen flex-1 flex-col justify-center items-center bg-neutral-50'>
            <div>
              <img className='w-[102px] h-[110px]' src={processingGif} alt='' />
            </div>
            <div className='mt-[48px] text-caption text-center'>
              Payment is being processed. Please wait.
            </div>
          </div>
        </>
      )}
    </Fragment>
  )
}

export default ProcessingTransaction
