import { useState, useEffect } from 'react'
import { IconButton } from '@material-tailwind/react'
import { Checkbox, Space, Drawer } from 'antd'
import moment from 'moment'
import { DatePicker } from '../../components'

const FilterModal = ({
  isOpen,
  handleOpenDrawer,
  type,
  handleReset,
  applyFilter,
  handleToDate,
  handleFromDate
  // handleOwnership
}: {
  isOpen: boolean
  handleOpenDrawer: () => void
  type: string
  handleReset: () => void
  applyFilter: (
    isMultiple: boolean,
    from: Date,
    to: Date,
    self: boolean,
    others: boolean,
    another: boolean,
    used: boolean,
    pending: boolean,
    lim: number
  ) => void
  handleToDate: (date: string | any | null) => void
  handleFromDate: (date: string | any | null) => void
}) => {
  const [fromDate, setFromDate] = useState<string | any | null>(null)
  const [toDate, setToDate] = useState<string | null | any>(null)
  const [minDate, setMinDate] = useState<string | null | any>(null)
  const [self, setSelf] = useState<boolean>(false)
  const [others, setOthers] = useState<boolean>(false)
  const [another, setAnother] = useState<boolean>(false)
  const [used, setUsed] = useState<boolean>(false)
  const [pending, setPending] = useState<boolean>(false)

  useEffect(() => {
    const fromDateToMilliseconds = Date.parse(fromDate)
    // console.log(fromDateToMilliseconds)
    const aDayToDate = fromDateToMilliseconds + 86400000
    const fromMillisecondsToString = new Date(aDayToDate).toLocaleDateString()
    const splitedDate = fromMillisecondsToString.split('/')
    const correctDateFormat = `${splitedDate[2]}-${splitedDate[0].length < 2 ? 0 : ''}${splitedDate[0]}-${splitedDate[1]}`
    setMinDate(moment(correctDateFormat).toDate())
  }, [fromDate])

  let isMultipleSelection = false
  if (
    (self && others) ||
    (self && another) ||
    (others && another) ||
    (self && others && another) ||
    (used && others) ||
    (used && pending) ||
    (others && pending) ||
    (used && others && pending)
  )
    isMultipleSelection = true

  const fromDateHandler = (date: Date) => {
    setFromDate(moment(date))

    handleFromDate(moment(date))
  }

  const toDateHandler = (date: Date) => {
    setToDate(moment(date))

    handleToDate(moment(date))
  }

  //console.log(toDate, fromDate)

  return (
    <>
      <Drawer
        closeIcon={null}
        placement='bottom'
        open={isOpen}
        className='rounded-t-[30px] shadow-xl bg-white dark:bg-dark-100 h-fit'
        height={type === 'discounts' ? 260 : type === 'archive' ? 400 : 375}
      >
        <div className='h-full'>
          <div className='mb-3 flex items-center justify-between pt-8 px-5'>
            <div className='text-header-4'>Filter by</div>
            <IconButton
              variant='text'
              color='blue-gray'
              onClick={handleOpenDrawer}
              placeholder=''
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='30'
                height='30'
                viewBox='0 0 30 30'
                fill='none'
              >
                <path
                  d='M10.3519 21.9445C10.0083 21.9445 9.66463 21.8179 9.39335 21.5466C8.86888 21.0222 8.86888 20.1541 9.39335 19.6296L19.6296 9.39335C20.1541 8.86888 21.0222 8.86888 21.5466 9.39335C22.0711 9.91783 22.0711 10.7859 21.5466 11.3104L11.3104 21.5466C11.0572 21.8179 10.6955 21.9445 10.3519 21.9445Z'
                  fill='#484442'
                />
                <path
                  d='M20.5881 21.9445C20.2445 21.9445 19.9009 21.8179 19.6296 21.5466L9.39335 11.3104C8.86888 10.7859 8.86888 9.91783 9.39335 9.39335C9.91783 8.86888 10.7859 8.86888 11.3104 9.39335L21.5466 19.6296C22.0711 20.1541 22.0711 21.0222 21.5466 21.5466C21.2754 21.8179 20.9317 21.9445 20.5881 21.9445Z'
                  fill='#484442'
                />
              </svg>
            </IconButton>
          </div>
          <div className='mt-5 pb-4 mx-5'>
            <div className='text-neutral-300 font-semibold mb-3'>Date</div>
            <div className='flex items-center gap-x-2'>
              <div className=' flex-1'>
                <div className='text-caption ml-1 py-1'>From</div>

                <DatePicker
                  onChange={fromDateHandler}
                  placeholder='yyyy-mm-dd'
                  style={{ border: '1px solid gray' }}
                  maxDate={moment()}
                />
              </div>
              <div className=' flex-1'>
                <div className='text-caption ml-1 py-1'>To</div>

                <DatePicker
                  onChange={toDateHandler}
                  placeholder='yyyy-mm-dd'
                  minDate={moment(minDate)}
                  defaultPickerValue={moment(minDate)}
                  style={{ border: '1px solid gray' }}
                  disabled={fromDate ? false : true}
                />
              </div>
            </div>
          </div>
          <hr />
          <div className='mt-5 mx-[20px]'>
            <div className='text-neutral-300 font-semibold mb-5'>
              {type === 'discounts'
                ? ''
                : type === 'archive'
                  ? 'Coupon status'
                  : 'Coupon ownership'}
            </div>
            {type === 'discounts' ? (
              <></>
            ) : (
              <div className='mb-5'>
                {type === 'archive' && (
                  <Space direction='vertical'>
                    <Checkbox
                      checked={used}
                      onChange={() => setUsed((prev) => !prev)}
                      className=' dark:text-neutral-300 accent-[#A53D00]'
                    >
                      Used
                    </Checkbox>
                    <Checkbox
                      checked={others}
                      onChange={() => setOthers((prev) => !prev)}
                      className=' dark:text-neutral-300 accent-[#A53D00]'
                    >
                      Others
                    </Checkbox>
                    <Checkbox
                      checked={pending}
                      onChange={() => {
                        setPending((prev) => !prev)
                      }}
                      className=' dark:text-neutral-300 accent-[#A53D00]'
                    >
                      Pending Payment
                    </Checkbox>
                  </Space>
                )}
                {type === 'available' && (
                  <Space direction='vertical'>
                    <Checkbox
                      checked={self}
                      onChange={() => setSelf((prev) => !prev)}
                      className=' dark:text-neutral-300 accent-[#A53D00]'
                    >
                      Self
                    </Checkbox>

                    <Checkbox
                      checked={another}
                      onChange={() => {
                        setAnother((prev) => !prev)
                      }}
                      className=' dark:text-neutral-300 accent-[#A53D00]'
                    >
                      Purchased for you
                    </Checkbox>
                  </Space>
                )}
                {type === 'others' && (
                  <Space direction='vertical'>
                    <Checkbox
                      checked={others}
                      onChange={() => setOthers((prev) => !prev)}
                      className=' dark:text-neutral-300 accent-[#A53D00]'
                    >
                      Purchased for others
                    </Checkbox>
                    <Checkbox
                      checked={false}
                      onChange={() => {}}
                      className=' dark:text-neutral-300 accent-[#A53D00]'
                    >
                      Shared with others
                    </Checkbox>
                  </Space>
                )}

                {type === 'used' && (
                  <Space direction='vertical'>
                    <Checkbox
                      checked={self}
                      onChange={() => setSelf((prev) => !prev)}
                      className=' dark:text-neutral-300 accent-[#A53D00]'
                    >
                      Self
                    </Checkbox>

                    <Checkbox
                      checked={another}
                      onChange={() => {
                        setAnother((prev) => !prev)
                      }}
                      className=' dark:text-neutral-300 accent-[#A53D00]'
                    >
                      Purchased for you
                    </Checkbox>
                  </Space>
                )}

                {type === 'pending' && (
                  <Space direction='vertical'>
                    <Checkbox
                      checked={self}
                      onChange={() => setSelf((prev) => !prev)}
                      className=' dark:text-neutral-300 accent-[#A53D00]'
                    >
                      Self
                    </Checkbox>
                    <Checkbox
                      checked={others}
                      onChange={() => setOthers((prev) => !prev)}
                      className=' dark:text-neutral-300 accent-[#A53D00]'
                    >
                      Others
                    </Checkbox>
                  </Space>
                )}
              </div>
            )}
          </div>
          <div className='flex items-center justify-evenly mx-[20px]'>
            <button
              onClick={() => {
                setFromDate(null)
                setToDate(null)
                setSelf(false)
                setOthers(false)
                setAnother(false)
                setUsed(false)
                setPending(false)
                handleReset()
              }}
              className='btn-subtle'
            >
              Clear filters
            </button>
            <button
              onClick={() => {
                // console.log(from, to)
                applyFilter(
                  isMultipleSelection,
                  fromDate,
                  toDate,
                  self,
                  others,
                  another,
                  used,
                  pending,
                  12
                )
              }}
              className='rounded-[8px] bg-primary py-2 px-8 text-white '
            >
              Apply
            </button>
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default FilterModal
