import { Modal } from 'antd'

type ArchivePromptTypes = {
  coupons: any[]
  openPrompt: boolean
  isArchiving: string
  title: string
  acceptArchiveHandler: () => void
  cancelArchiveHandler: () => void
  handleArchive: () => void
}

function ArchivePrompt({
  acceptArchiveHandler,
  cancelArchiveHandler,
  openPrompt,
  isArchiving,
  title,
  handleArchive
}: ArchivePromptTypes) {
  let content = (
    <div>
      <p className='text-sm text-[#484442] w-[268px] mb-5'>
        {title === 'archive'
          ? 'Unarchiving coupons will add the coupons to the coupons list.'
          : 'Archiving coupons will remove the coupons from the coupons list.'}
      </p>
      <div className='flex gap-x-3 justify-end items-center text-xs'>
        <button className='text-[#484442]' onClick={cancelArchiveHandler}>
          Cancel
        </button>
        <button className=' text-[#F15B50]' onClick={acceptArchiveHandler}>
          {title === 'archive' ? 'Unarchive coupons' : 'Archive coupons'}
        </button>
      </div>
    </div>
  )

  if (isArchiving === 'archiving') {
    content = (
      <div className='flex flex-col items-center justify-center'>
        <div className='loader mb-5'></div>
        {title === 'archive' ? (
          <p>Unarchiving coupons</p>
        ) : (
          <p>Archiving coupons</p>
        )}
      </div>
    )
  }

  if (isArchiving === 'archived') {
    content = (
      <div className='flex flex-col items-center justify-center'>
        <svg
          width='32'
          height='31'
          viewBox='0 0 32 31'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect
            x='1.58008'
            y='1.5'
            width='28.84'
            height='27.5781'
            rx='13.789'
            stroke='#2ABB7F'
            stroke-width='3'
          />
          <path
            d='M8.08008 15.289L13.3691 20.5781L23.9201 10'
            stroke='#2ABB7F'
            stroke-width='2.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>
        <p className='text-[#2ABB7F] mb-6'>
          {title === 'archive'
            ? 'Coupons unarchived successfully'
            : 'Coupons archived successfully'}
        </p>
        <p className='text-[#A53D00] cursor-pointer' onClick={handleArchive}>
          View coupons page
        </p>
      </div>
    )
  }

  return (
    <Modal
      title={
        <p className=' font-semibold text-sm text-[#484442]'>
          {isArchiving === 'archived' ? ' ' : 'Archive coupons'}
        </p>
      }
      open={openPrompt}
      onCancel={cancelArchiveHandler}
      footer={null}
      centered
    >
      {content}
    </Modal>
  )
}

export default ArchivePrompt
