import { useState, useContext } from 'react'
import CustomNavigation from '../../components/CustomNavigation'
import SideBar from '../../components/SideBar'
import Header from '../../components/Header'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { Input } from 'antd'
import ProfileModal from '../../components/ProfileModal'

// import {
//   EnvelopeIcon,
//   PowerIcon,
//   ShieldExclamationIcon,
// } from '@heroicons/react/24/outline';
//import { UserIcon, InformationCircleIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom'
import { StorageBox } from '../../helpers/stroage'

// import Header from "../../components/Header";
import LogoutModal from './LogoutModal'
import { DrawerContext } from '../../components/Providers'

const SettingsScreen = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const drawerctx = useContext(DrawerContext)

  const [showDelete, setShowDelete] = useState(false)
  const [verifyDelete, setVerifyDelete] = useState(false)
  const [pin, setPin] = useState('')

  const handleLogout = () => {
    StorageBox.clearStorage()
    window.location.reload()
  }

  const showDrawer = () => {
    // setShowMenu(true)
    drawerctx.toggleShow(true)
  }

  const onClose = () => {
    // setShowMenu(false)
    drawerctx.toggleShow(false)
  }

  const pinHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numberRegex = /^\d+$/
    if (numberRegex.test(e.target.value) || e.target.value === '') {
      setPin(e.target.value)
    }
  }

  // logic to delete and individual's account
  const handleDeleteAccount = () => {}

  //logic to handle dark mode
  // const handleDarkMode = () => {
  //   darkctx.changeDarkMode(!darkctx.darkMode)
  //   localStorage.setItem('darkMode', `${!darkctx.darkMode}`)
  // }

  //console.log(darkctx.darkMode)

  return (
    <div className='w-full'>
      <ProfileModal
        title='Delete Account'
        showModal={showDelete}
        removeModal={() => setShowDelete(false)}
      >
        <p className='text-[#484442] text-sm mb-3'>
          Are you sure you want to delete your account?
        </p>
        <p className='text-[#484442] text-sm'>
          Taking this action will delete this account and all data associated
          with it.
        </p>
        <div className='flex justify-end items-center gap-x-3 mt-5 text-sm'>
          <button onClick={() => setShowDelete(false)}>Cancel</button>
          <button
            onClick={() => {
              setShowDelete(false)
              setVerifyDelete(true)
            }}
          >
            Delete account
          </button>
        </div>
      </ProfileModal>
      <ProfileModal
        title='Delete Account'
        showModal={verifyDelete}
        removeModal={() => setVerifyDelete(false)}
      >
        <div className='px-5 py-2'>
          <p className='text-[#484442] text-sm mb-3'>Your pin</p>
          <Input.Password
            maxLength={4}
            className='h-12 border border-gray-700 focus-within:shadow-none focus-within:border-gray-700 text-xl'
            placeholder='Enter your pin'
            value={pin}
            onChange={pinHandler}
          />
          <div className='flex justify-end items-center gap-x-3 mt-5 text-sm'>
            <button
              onClick={() => {
                setVerifyDelete(false)
                setPin('')
              }}
            >
              Cancel
            </button>
            <button onClick={handleDeleteAccount}>Delete</button>
          </div>
        </div>
      </ProfileModal>
      <SideBar closeDrawer={onClose} show={drawerctx.show}>
        <CustomNavigation activeIndex={3} />
      </SideBar>
      <Header title='Settings' toggleMenu={showDrawer} page='Settings' />
      <div className='h-screen overflow-hidden dark:bg-dark-100 dark:text-white relative'>
        {/* <Header title="Settings" showExtra={false} /> */}
        {/* head */}
        <div className='mt-5'>
          <div className='h-full w-full'>
            {/* {tabNames.map((tab, i) => (
              <div
                key={i}
                className='cursor-pointer p-5 border-b-[1px] border-neutral-100 flex gap-4 items-center'
                onClick={() => navigate(tab.route)}
              >
                {tab?.icon}
                {tab?.name}
              </div>
            ))} */}
            <div
              className='cursor-pointer p-5 border-b-[1px] border-neutral-100 flex justify-between items-center'
              onClick={() => navigate('/personal')}
            >
              {/* <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  d='M16.8 2H14.2C11 2 9 4 9 7.2V11.25H15.25C15.66 11.25 16 11.59 16 12C16 12.41 15.66 12.75 15.25 12.75H9V16.8C9 20 11 22 14.2 22H16.79C19.99 22 21.99 20 21.99 16.8V7.2C22 4 20 2 16.8 2Z'
                  fill='#484442'
                />
                <path
                  d='M4.55994 11.2498L6.62994 9.17984C6.77994 9.02984 6.84994 8.83984 6.84994 8.64984C6.84994 8.45984 6.77994 8.25984 6.62994 8.11984C6.33994 7.82984 5.85994 7.82984 5.56994 8.11984L2.21994 11.4698C1.92994 11.7598 1.92994 12.2398 2.21994 12.5298L5.56994 15.8798C5.85994 16.1698 6.33994 16.1698 6.62994 15.8798C6.91994 15.5898 6.91994 15.1098 6.62994 14.8198L4.55994 12.7498H8.99994V11.2498H4.55994Z'
                  fill='#484442'
                />
              </svg> */}
              <div className='flex gap-4 items-center'>
                <img src='/setting-user.png' alt='user' />
                <div className='text-[#484442]'>
                  <p>Personal</p>
                  <p className='text-sm'>Set your personal settings</p>
                </div>
              </div>

              <ChevronRightIcon className='h-6 w-6 font-bold' />
            </div>
            <div
              className='cursor-pointer p-5 border-b-[1px] border-neutral-100 flex justify-between items-center'
              onClick={() => navigate('/app-info')}
            >
              {/* <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  d='M16.8 2H14.2C11 2 9 4 9 7.2V11.25H15.25C15.66 11.25 16 11.59 16 12C16 12.41 15.66 12.75 15.25 12.75H9V16.8C9 20 11 22 14.2 22H16.79C19.99 22 21.99 20 21.99 16.8V7.2C22 4 20 2 16.8 2Z'
                  fill='#484442'
                />
                <path
                  d='M4.55994 11.2498L6.62994 9.17984C6.77994 9.02984 6.84994 8.83984 6.84994 8.64984C6.84994 8.45984 6.77994 8.25984 6.62994 8.11984C6.33994 7.82984 5.85994 7.82984 5.56994 8.11984L2.21994 11.4698C1.92994 11.7598 1.92994 12.2398 2.21994 12.5298L5.56994 15.8798C5.85994 16.1698 6.33994 16.1698 6.62994 15.8798C6.91994 15.5898 6.91994 15.1098 6.62994 14.8198L4.55994 12.7498H8.99994V11.2498H4.55994Z'
                  fill='#484442'
                />
              </svg> */}
              <div className='flex gap-4 items-center'>
                <img src='/settings-info.png' alt='info' />
                <div className='text-[#484442]'>
                  <p>App info</p>
                  <p className='text-sm'>View application information</p>
                </div>
              </div>
              <ChevronRightIcon className='h-6 w-6 font-bold' />
            </div>

            <div
              className='cursor-pointer p-5 border-b-[1px] border-neutral-100 flex gap-4 items-center'
              onClick={() => setShowDelete(true)}
            >
              {/* <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  d='M16.8 2H14.2C11 2 9 4 9 7.2V11.25H15.25C15.66 11.25 16 11.59 16 12C16 12.41 15.66 12.75 15.25 12.75H9V16.8C9 20 11 22 14.2 22H16.79C19.99 22 21.99 20 21.99 16.8V7.2C22 4 20 2 16.8 2Z'
                  fill='#484442'
                />
                <path
                  d='M4.55994 11.2498L6.62994 9.17984C6.77994 9.02984 6.84994 8.83984 6.84994 8.64984C6.84994 8.45984 6.77994 8.25984 6.62994 8.11984C6.33994 7.82984 5.85994 7.82984 5.56994 8.11984L2.21994 11.4698C1.92994 11.7598 1.92994 12.2398 2.21994 12.5298L5.56994 15.8798C5.85994 16.1698 6.33994 16.1698 6.62994 15.8798C6.91994 15.5898 6.91994 15.1098 6.62994 14.8198L4.55994 12.7498H8.99994V11.2498H4.55994Z'
                  fill='#484442'
                />
              </svg> */}
              <img src='/settings-trash.png' alt='delete' />
              <div className='text-[#484442]'>
                <p>Delete Account</p>
                <p className='text-sm'>
                  Delete account and all data associated with it
                </p>
              </div>
            </div>
            <div
              className='cursor-pointer p-5 border-b-[1px] border-neutral-100 flex gap-4 items-center'
              onClick={() => setOpen(true)}
            >
              {/* <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  d='M16.8 2H14.2C11 2 9 4 9 7.2V11.25H15.25C15.66 11.25 16 11.59 16 12C16 12.41 15.66 12.75 15.25 12.75H9V16.8C9 20 11 22 14.2 22H16.79C19.99 22 21.99 20 21.99 16.8V7.2C22 4 20 2 16.8 2Z'
                  fill='#484442'
                />
                <path
                  d='M4.55994 11.2498L6.62994 9.17984C6.77994 9.02984 6.84994 8.83984 6.84994 8.64984C6.84994 8.45984 6.77994 8.25984 6.62994 8.11984C6.33994 7.82984 5.85994 7.82984 5.56994 8.11984L2.21994 11.4698C1.92994 11.7598 1.92994 12.2398 2.21994 12.5298L5.56994 15.8798C5.85994 16.1698 6.33994 16.1698 6.62994 15.8798C6.91994 15.5898 6.91994 15.1098 6.62994 14.8198L4.55994 12.7498H8.99994V11.2498H4.55994Z'
                  fill='#484442'
                />
              </svg> */}
              <img src='/logout.png' alt='delete' />
              <div>
                <p className='text-[#F15B50]'>Logout</p>
                <p className='text-[#484442] text-sm'>
                  Remove this account from this device
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* foot */}
        {/* <div className='fixed w-full bottom-0 bg-gradient-to-r from-orange-700 via-orange-800 to-orange-800'>
          <CustomNavigation activeIndex={3} />
        </div> */}

        <LogoutModal
          isOpen={open}
          handleOpenDrawer={() => setOpen(false)}
          handleLogout={() => handleLogout()}
        />
      </div>
    </div>
  )
}

export default SettingsScreen
