import React, { useState } from 'react'
import type { DrawerProps } from 'antd'
import { Drawer } from 'antd'

type SideBarProps = {
  children: React.ReactNode
  show: boolean
  closeDrawer: () => void
}

const SideBar = ({ children, closeDrawer, show }: SideBarProps) => {
  const [placement] = useState<DrawerProps['placement']>('left')

  return (
    <Drawer
      placement={placement}
      closable={false}
      onClose={closeDrawer}
      open={show}
      key={placement}
      width='70%'
    >
      {children}
    </Drawer>
  )
}

export default SideBar
