import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.scss'
import SignInScreen from './pages/auth/signin'
import SignUpScreen from './pages/auth/signup'
import HomeScreen from './pages/home/home'
import SettingsScreen from './pages/settings/settings'
import SelfFuel from './pages/home/buyFuel/self/selfFuel'
import PaymentOption from './pages/home/buyFuel/self/paymentOption'
import OtherFuel from './pages/home/buyFuel/others/otherFuel'
import PersonalInfo from './pages/settings/personal'
import SecuritySettings from './pages/settings/security'
import ChangePin from './pages/settings/changePin'
import ProtectedRoute from './HOC/ProtectedRoute'
import CouponDetails from './pages/home/couponDetails'
import Transactions from './pages/history/transactions'
import OtherPaymentOption from './pages/home/buyFuel/others/paymentOption'
import ContactUs from './pages/settings/contact-us'
import toMobile from './images/toMobile.svg'
import BranchesScreen from './pages/branches/branches'
import ForgotPin from './pages/auth/forgotPin'
import CouponsPage from './pages/home/couponsPage'
import AppInfo from './pages/settings/appInfo'
import CouponItems from './pages/couponItems'
// import AllCoupons from './pages/home/AllCoupons'
import Notification from './pages/home/Notification'
import { ConfigProvider } from 'antd'
import RedirectToHomeRoute from './HOC/RedirectToHomeRoute'
import Regions from './pages/branches/regions'
import RePay from './components/Repayment'
import RePayOthers from './components/OthersRepayment'
import CouponSharing from './pages/CouponSharing'
import History from './pages/History'
// const Onboard = lazy(() => import('./pages/Onboard'))
import Onboard from './pages/Onboard'
import PaymentDetails from './pages/home/buyFuel/others/PaymentDetails'

function App() {
  const [isMobile, setIsMobile] = useState(true)

  // useEffect to check the screen size on initial render
  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', checkScreenSize)

    checkScreenSize()

    return () => {
      window.removeEventListener('resize', checkScreenSize)
    }
  }, [])

  if (!isMobile) {
    return (
      <div className='h-screen overflow-hidden flex justify-center items-center'>
        <div>
          <img className='w-[500px]' src={toMobile} alt='mob' />
          <p className='text-lg mt-4 text-center'>
            Please switch to a mobile device to access this app.
          </p>
        </div>
      </div>
    )
  }

  // Render the app if it's a mobile device
  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#7C0000' } }}>
      <Router>
        <Routes>
          <Route path='/*' element={<h4>Page not found!</h4>} />

          <Route
            path='/'
            element={
              <RedirectToHomeRoute>
                <Onboard />
              </RedirectToHomeRoute>
            }
          />
          <Route
            path='/signup'
            element={
              <RedirectToHomeRoute>
                <SignUpScreen />
              </RedirectToHomeRoute>
            }
          />

          <Route
            path='/signin'
            element={
              <RedirectToHomeRoute>
                <SignInScreen />
              </RedirectToHomeRoute>
            }
          />
          <Route
            path='/forgot-pin'
            element={
              <RedirectToHomeRoute>
                <ForgotPin />
              </RedirectToHomeRoute>
            }
          />

          <Route
            path='/home'
            element={
              <ProtectedRoute>
                <HomeScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path='/stations'
            element={
              <ProtectedRoute>
                <Regions />
              </ProtectedRoute>
            }
          />
          <Route
            path='/branches/:id'
            element={
              <ProtectedRoute>
                <BranchesScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path='/transactions'
            element={
              <ProtectedRoute>
                <Transactions />
              </ProtectedRoute>
            }
          />
          <Route
            path='/settings'
            element={
              <ProtectedRoute>
                <SettingsScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path='/app-info'
            element={
              <ProtectedRoute>
                <AppInfo />
              </ProtectedRoute>
            }
          />
          <Route
            path='/contact-us'
            element={
              <ProtectedRoute>
                <ContactUs />
              </ProtectedRoute>
            }
          />
          <Route
            path='/self-fuel'
            element={
              <ProtectedRoute>
                <SelfFuel />
              </ProtectedRoute>
            }
          />
          <Route
            path='/payment'
            element={
              <ProtectedRoute>
                <PaymentOption />
              </ProtectedRoute>
            }
          />
          <Route
            path='/:id/share-coupon'
            element={
              <ProtectedRoute>
                <CouponSharing />
              </ProtectedRoute>
            }
          />
          <Route
            path='/history/:id'
            element={
              <ProtectedRoute>
                <History />
              </ProtectedRoute>
            }
          />
          <Route
            path='/retry-payment'
            element={
              <ProtectedRoute>
                <RePay />
              </ProtectedRoute>
            }
          />
          <Route
            path='/retry-payment-others'
            element={
              <ProtectedRoute>
                <RePayOthers />
              </ProtectedRoute>
            }
          />
          <Route
            path='/other-payment/details/:id'
            element={
              <ProtectedRoute>
                <PaymentDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path='/other-payment'
            element={
              <ProtectedRoute>
                <OtherPaymentOption />
              </ProtectedRoute>
            }
          />
          <Route
            path='/other-fuel'
            element={
              <ProtectedRoute>
                <OtherFuel />
              </ProtectedRoute>
            }
          />
          <Route
            path='/personal'
            element={
              <ProtectedRoute>
                <PersonalInfo />
              </ProtectedRoute>
            }
          />
          <Route
            path='/security'
            element={
              <ProtectedRoute>
                <SecuritySettings />
              </ProtectedRoute>
            }
          />
          <Route
            path='/coupons'
            element={
              <ProtectedRoute>
                <CouponsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path='/all-coupons'
            element={
              <ProtectedRoute>
                <CouponItems />
              </ProtectedRoute>
            }
          />
          <Route
            path='/notifications'
            element={
              <ProtectedRoute>
                <Notification />
              </ProtectedRoute>
            }
          />

          <Route
            path='/change-pin'
            element={
              <ProtectedRoute>
                <ChangePin />
              </ProtectedRoute>
            }
          />
          <Route path='/coupons/:id' element={<CouponDetails />} />
        </Routes>
      </Router>
    </ConfigProvider>
  )
}

export default App
