import moment from 'moment'
// import { useState } from 'react'
import { Checkbox } from 'antd'
import type { CheckboxProps } from 'antd'

const CouponCard = ({
  coupon,
  onCouponSelect,
  payCoupon,
  handleOpenTransfer,
  title,
  fuelType,
  addToList,
  removeFromList
}: any) => {
  // const [selected, setSelected] = useState(false)

  const checkBoxHandler: CheckboxProps['onChange'] = (e) => {
    // console.log(`checked = ${e.target.checked}`)
    // setSelected(e.target.checked)
    if (e.target.checked) {
      addToList(coupon.id)
    } else {
      removeFromList(coupon.id)
    }
  }

  return (
    <div className='coupon-card-container cursor-pointer mb-5 dark:bg-dark-100 dark:text-white dark:shadow-sm dark:shadow-gray-700'>
      <div
        className={`flex ${title === 'pending' || title === 'others' || title === 'used' ? 'justify-end' : 'justify-between'} items-center`}
      >
        {title === 'pending' || title === 'others' || title === 'used' ? (
          <></>
        ) : coupon?.status?.code === 'NOT_USED' &&
          coupon?.payment_status?.code === 'COMPLETED' ? (
          <div className='bg-[#c5ddfe] text-[#388BFF] w-fit h-[22px] rounded-[8px] px-[8px] py-1 text-[12px] flex justify-center items-center'>
            Not used
          </div>
        ) : coupon?.status?.code === 'PARTIALLY_USED' &&
          coupon?.payment_status?.code === 'COMPLETED' ? (
          <div className='bg-[#FFF7D6] text-[#CF9F02] w-fit h-[22px] rounded-[8px] px-[8px] py-1 text-[12px] flex justify-center items-center'>
            Partially used
          </div>
        ) : coupon?.status?.code === 'USED' &&
          coupon?.payment_status?.code === 'COMPLETED' ? (
          <div className='bg-[#D4D3D3] text-[#484442] w-fit h-[22px] rounded-[8px] px-[8px] py-1 text-[12px] flex justify-center items-center'>
            Used
          </div>
        ) : coupon?.payment_status?.code === 'PENDING' ? (
          <div className='bg-[#FFECEB] text-[#F15B50] w-fit h-[22px] rounded-[8px] px-[8px] py-1 text-[12px] flex justify-center items-center'>
            Pending payment
          </div>
        ) : (
          <></>
        )}
        {title === 'available' &&
          (fuelType === 'super' ||
            fuelType === 'diesel' ||
            fuelType === 'v-power') && (
            <Checkbox
              onChange={checkBoxHandler}
              checked={coupon.checked}
              className='accent-[#A53D00]'
            />
          )}
        {(title === 'used' ||
          title === 'pending' ||
          title === 'others' ||
          title === 'archive') && (
          <Checkbox
            onChange={checkBoxHandler}
            checked={coupon.checked}
            className='accent-[#A53D00]'
          />
        )}
      </div>
      <div className='my-4 flex justify-between items-center'>
        <div className='flex items-center gap-2'>
          <div className='w-[37px] h-[37px]'>
            <img src={coupon?.company?.image_url} alt='company' />
            <div className='text-primary font-semibold text-center'>
              {/* {coupon?.company?.name} */}
            </div>
          </div>
          <div>
            <div className='text-header-4'>
              {coupon?.currency}
              {/* {Intl.NumberFormat('en-EN').format(Number(coupon?.price))} */}
              {coupon?.price.toFixed(2)}
            </div>
            <div className='text-xs text-neutral-200'>
              {coupon?.currency}
              {/* {Intl.NumberFormat('en-EN').format(
                Number(coupon?.amount_used)
              )} */}
              {coupon?.amount_left.toFixed(2)} left
            </div>
            <div className='text-xs text-neutral-200'>
              Purchased on {moment(coupon?.created_at).format('Do MMM YY')}
            </div>
          </div>
        </div>
        {coupon.payment_status.code === 'COMPLETED' ? (
          <button
            onClick={onCouponSelect}
            className='bg-[#A53D00] py-[7px] px-[16px] rounded-lg text-white'
          >
            View
          </button>
        ) : (
          <button
            onClick={payCoupon}
            className='bg-[#A53D00] py-[7px] px-[16px] rounded-lg text-white'
          >
            Pay
          </button>
        )}
      </div>
    </div>
  )
}

export default CouponCard
