import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { Select } from 'antd'
import { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BaseService from '../../../../helpers/baseServices'
import { global_variables } from '../../../../helpers/globalVariables'
import Button from '../../../../components/Button'

const SelfFuel = () => {
  const navigate = useNavigate()
  const [allCompanies, setAllCompanies] = useState([])
  const [fecthingCompanies, setFecthingCompanies] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState('')
  const [, setSelectError] = useState(false)
  const [theAmount, setTheAmount] = useState('')
  const [amountError, setAmountError] = useState(false)
  const [fuelType, setFuelType] = useState('')
  const [, setTypeErr] = useState(false)
  const [fetchingTypes, setFetchingTypes] = useState(false)
  const [allFuelTypes, setAllFuelTypes] = useState([])

  //fetch comapnies
  const fetchCompanies = async () => {
    setFecthingCompanies(true)
    try {
      const response = await BaseService.get_api(
        `${global_variables.get_companies}`
      )
      //console.log(response)
      setAllCompanies(response?.data?.payload?.items)
      setFecthingCompanies(false)
    } catch (error) {
      // console.log(error)
    }
  }

  //fetchFuelTypes
  const fetchFuelTypes = async () => {
    setFetchingTypes(true)
    try {
      const response = await BaseService.get_api(
        `${global_variables.get_fuel_types}`
      )
      // console.log(response?.data)
      setAllFuelTypes(response?.data?.payload?.items)
      setFetchingTypes(false)
    } catch (error) {
      console.log(error)
    }
  }

  //handle on company change
  // const onCompanyChange = (selected: any) => {
  //   // console.log(selected)
  //   setSelectedCompany(selected)
  // }

  //handle continue
  const handleContinue = () => {
    if (!selectedCompany) {
      setSelectError(true)
    } else if (!fuelType) {
      setTypeErr(true)
    } else if (!theAmount) {
      setAmountError(true)
    } else {
      let dataForPayment = JSON.stringify({
        price: parseFloat(theAmount).toFixed(2),
        type: 'SELF',
        fuel_type_code: fuelType,
        company_code: selectedCompany
      })
      localStorage.setItem('dataForPayment', dataForPayment)
      navigate('/payment')
    }
  }

  const handleAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regExp = /^[+]?((\d+(\.\d*)?)|(\.\d+))*$/
    //console.log(e.target.value)
    if (regExp.test(e.target.value)) {
      setTheAmount(parseFloat(e.target.value) >= 0 ? e.target.value : '')
    }
  }

  useEffect(() => {
    fetchCompanies()
    fetchFuelTypes()
  }, [])

  return (
    <Fragment>
      <div className='h-screen overflow-hidden bg-neutral-50 dark:bg-dark-100'>
        <div className='h-full mx-[20px] mt-5'>
          <div className='flex items-center'>
            <div className='flex-[0.3]'>
              <button
                className='h-10 text-neutral-300 flex justify-center items-center'
                onClick={() => navigate(-1)}
              >
                <ChevronLeftIcon className='h-6 w-6 font-bold' />
              </button>
            </div>
            <div className='flex-[0.7]'>
              <h4 className='text-header-4'>Buy for self</h4>
            </div>
          </div>

          <div className='mt-10'>
            <div className='flex flex-col'>
              <small className='ml-1 mb-2 text-caption'>
                Select fuel company
              </small>
              <Select
                // label='Select fuel company'
                className='h-[50px] bg-white rounded-lg border border-gray-500'
                placeholder='Select fuel company'
                onChange={(val: any) => setSelectedCompany(val)}
                // error={selectError}
                disabled={fecthingCompanies}
                options={allCompanies.map((company: any, i: number) => ({
                  value: company?.code,
                  label: company?.name
                }))}
              />
              {/* {allCompanies.map((comapany: any, i: number) => (
                  <Option  key={i} value={comapany?.code}>
                    {comapany?.name}
                  </Option>
                ))}
              </Select> */}
            </div>
            <div className='mt-[16px] flex flex-col'>
              <small className='ml-1 mb-2 text-caption'>Select fuel type</small>
              <Select
                // label='Select fuel type'
                placeholder='Select fuel type'
                className=' h-[50px] bg-white rounded-lg border border-gray-500'
                onChange={(val: any) => setFuelType(val)}
                // error={typeErr}
                disabled={fetchingTypes}
                options={allFuelTypes.map((item: any, i: number) => ({
                  value: item?.code,
                  label: item?.name
                }))}
              />
              {/* {allFuelTypes.map((item: any, i: number) => (
                  <Option key={i} value={item?.code}>
                    {item?.name}
                  </Option>
                ))}
              </Select> */}
            </div>
            <div className='mt-[16px]'>
              <small
                className={`${
                  amountError ? 'text-red-500 ml-1' : 'ml-1 text-caption'
                }`}
              >
                Amount to buy
              </small>
              <input
                className='input-container w-full focus:outline-none'
                placeholder='Enter amount'
                // type='number'
                value={theAmount}
                onChange={handleAmount}
                style={{ color: 'black' }}
              />
            </div>
          </div>

          <div className='mt-[50px] flex justify-center items-center'>
            <Button width='w-full' onClick={() => handleContinue()}>
              Continue
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default SelfFuel
