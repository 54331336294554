import { useState } from 'react'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { useNavigate, useLocation } from 'react-router-dom'
import { Spinner } from '@material-tailwind/react'
import moment from 'moment'

const History = () => {
  const [isLoading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const couponData = location.state
  //console.log(couponData)

  const convertDate = new Date(couponData?.coupon?.created_at)
  const couponDate = moment(convertDate).calendar()

  return (
    <div className=' dark:bg-dark-100 dark:text-white h-screen'>
      <div className='py-5  dark:bg-dark-100 dark:text-white'>
        <div className='flex justify-between items-center px-5 mt-5 bg-white'>
          <div className='flex-[0.3]'>
            <button className='text-neutral-300' onClick={() => navigate(-1)}>
              <ChevronLeftIcon className='h-5 w-5' />
            </button>
          </div>
          <div className='flex-[0.7]'>
            <h4 className='header-text-4'>Coupon history</h4>
          </div>
          <div className='flex gap-1 items-center text-[#A53D00]'>
            <img src='/download.png' alt='download' onClick={() => { }} />
            <span>Download</span>
          </div>
        </div>
        <div>
          {isLoading ? (
            <div className='flex justify-center items-center h-full'>
              <Spinner />
            </div>
          ) : (
            <div className='mt-7'>
              <div className='px-5'>
                <p className='flex justify-between items-center mb-5'>
                  <span>Amount</span>
                  <span className=' font-semibold'>
                    {' '}
                    GH &#8373; {(couponData?.coupon?.price * 1.0).toFixed(2)}
                  </span>
                </p>
                <p className='flex justify-between items-center mb-5'>
                  <span>Created on</span>
                  <span className=' font-semibold'>{couponDate}</span>
                </p>
              </div>
              <hr className='border border-gray-500 mb-7' />
              <div className='px-5'>
                {couponData?.history?.items?.length > 0 &&
                  couponData?.history?.items?.map((coupon: any) => {
                    let couponDate = ''
                    let modifiedDate = moment(coupon.created_at)
                      .calendar()
                      .split(' ')[0]
                    if (
                      modifiedDate === 'Today' ||
                      modifiedDate === 'Yesterday'
                    ) {
                      couponDate = modifiedDate
                    } else {
                      couponDate = moment(coupon.created_at).calendar()
                    }

                    return (
                      <div key={coupon.id}>
                        <h3 className='my-5 mx-5 font-semibold text-lg'>
                          {couponDate}
                        </h3>
                        <div className='mx-5 mb-5'>
                          <div className='flex justify-between mb-2'>
                            <h3
                              className={`font-semibold ${coupon.event.name.includes('failed') ? 'text-red-500' : ''}`}
                            >
                              {coupon?.title}
                            </h3>
                          </div>
                          <p>{coupon?.description}</p>
                        </div>
                        <hr className='border border-gray-500 mb-5' />
                      </div>
                    )
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default History
